//
// Pagination
//

.page-item {
  &.active .page-link {
    box-shadow: $pagination-active-box-shadow;
  }

  .page-link,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 3px;
    border-radius: 50% !important;
    width: 32px;
    height: 32px;
    font-size: $font-size-sm;
  }
}

// Size variations

.pagination-lg {
  .page-item {
    .page-link,
    span {
      width: 46px;
      height: 46px;
      line-height: 46px;
    }
  }
}

.pagination-sm {
  .page-item {
    .page-link,
    span {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
}

.pagination-padding {
  padding: 0px 30px;
}
