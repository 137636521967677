//
// Helper
// helper classes for different cases
//

.e-rte-fontcolor-element {
  display: none !important;
}

// Clearfix for sections that use float property

.floatfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

//
// labels
//
.label-success {
  border: 2px solid #81C04D;
  padding: 6px 0px;
  border-radius: 16px;
  color: #81C04D;
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

.label-danger {
  border: 2px solid #FF5555;
  padding: 6px 0px;
  border-radius: 16px;
  color: #FF5555;
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

.label-critico {
  border: 2px solid #DA6D6D;
  padding: 6px 0px;
  border-radius: 16px;
  color: #DA6D6D;
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

.label-info {
  border: 2px solid #11CDEF;
  padding: 6px 0px;
  border-radius: 16px;
  color: #11CDEF;
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

.label-warning {
  border: 2px solid #FFB346;
  padding: 6px 0px;
  border-radius: 16px;
  color: #FFB346;
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

.label-default {
  border: 2px solid #868686;
  padding: 6px 0px;
  border-radius: 16px;
  color: #868686;
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

.label-dark {
  border: 2px solid #222222;
  padding: 6px 0px;
  border-radius: 16px;
  color: #222222;
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

.label-planned {
  border: 2px solid rgb(55, 151, 153);
  padding: 6px 0px;
  border-radius: 16px;
  color: rgb(55, 151, 153);
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

.div-filters {
  box-shadow: -1px 2px 42px 11px #e9ecef;
  border-radius: 15px;
  height: 55px;
  display: flex;
  padding-left: 9px;
  margin-bottom: 12px;
}

.ancora-links-modal {
  height: 56px;
  display: flex;
  border: 1px solid #CFD1D3;
  border-radius: 11px;
  width: 1237px;
}

.row-fixed-modal {
  position: fixed;
  z-index: 99999;
  background-color: white;
  padding: 21px 0px;
  top: 97px;
}

.label-nodata {
  border: 2px solid #8898aa;
  padding: 6px 0px;
  border-radius: 16px;
  color: #8898aa;
  font-weight: 700;
  width: 100%;
  display: block;
  text-align: center;
}

.label-medium-lv {
  border: 2px solid #FBB040;
  padding: 6px 0px;
  border-radius: 16px;
  color: #FBB040;
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

.label-block-success {
  background-color: #bee6cf;
  border-left: 3px solid #56ce89;
  padding: 11px 0px;
  border-radius: 6px;
}

.label-block-warning {
  background-color: #ffd595;
  border-left: 3px solid #FBB040;
  padding: 11px 0px;
  border-radius: 6px;
}

.label-block-danger {
  background-color: #f7b6c4;
  border-left: 3px solid #c72c4e;
  padding: 11px 0px;
  border-radius: 6px;
}

.label-block-info {
  background-color: #C1EDF8;
  border-left: 3px solid #2aa5c4;
  padding: 11px 0px;
  border-radius: 6px;
}

.disabled {
  background-color: #e9ecef;
}


/* Switch button */
/*
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border: 1px solid #C6C6C6;
  -webkit-transition: .2s;
  transition: .2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 1px;
  top: 1px;
  background-color: #C6C6C6;
  -webkit-transition: .2s;
  transition: .2s;
}

input:checked + .slider {
  background-color: white !important;    
  border: 1px solid #81C04D !important;
}

input:focus + .slider {
  box-shadow: 0 0 0.5px #81C04D !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
  background-color: #81C04D;
}

.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
} */

.label-xl {
  width: 130px !important;
}

.label-disp-task-entrega {
  border: 2px solid #5E72E4;
  padding: 6px 0px;
  border-radius: 16px;
  color: #5E72E4;
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

.label-disp-task-retirada {
  border: 2px solid #9B549C;
  padding: 6px 0px;
  border-radius: 16px;
  color: #9B549C;
  font-weight: 700;
  width: 100px;
  display: block;
  text-align: center;
}

/* The radio-box */
.radio-box {
  position: relative;
  padding-left: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-box input {
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #DEDEDE;
  border-radius: 50%;
}

/* On mouse-over, add a background color */
.radio-box:hover input ~ .checkmark {
  background: #00D7A0;
}

/* When the radio button is checked, add a blue background */
.radio-box input:checked ~ .checkmark {
  background: #00D7A0;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-box input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-box .checkmark:after {
  top: 5px;
  left: 5px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: white;
}

.label-sm {
  width: 50px !important;
}

.required {
  transition: all 300ms ease-in-out;
  border-top: 2px solid red;
}

.required-contatos {
  border-top: 2px solid red;
  padding-top: 7px;
}

.form-control {
  transition: all 300ms ease-in-out !important;
}

color-picker > div.color-picker {
  padding: 4px;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.4375rem;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
}

#defaultRTE_toolbar_FontColor_Target {
  display: none;
}

.menu-dot{
  color: #3f73e9;
  padding-top: 12px;
  padding-left: 2px;
  font-size: 17px;
}

.template-preview-html {
  >p {
    margin-bottom: 1rem !important;
  }
}

/* Switch button */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #C6C6C6;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 3px !important;
  top: 3px !important;
  background-color: #C6C6C6;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  border: 1px solid #81C04D !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #81C04D !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #81C04D;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}