//
// Modal
//
/*.modal-header {
  border-bottom: 1px solid #CFD1D3;
}*/

.modal-title {
  font-size: $modal-title-font-size;
  padding-top: 4px;
}

// Fluid modal

.modal-fluid {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }

  .modal-content {
    border-radius: 0;
  }
}

// Background color variations

@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}

.modal-milvus {
  .modal-header-milvus {
    padding: 18px 1.5rem;
    border-bottom: 1px solid #CFD1D3;
    display: flex;
    align-items: center;
    min-height: 60px;
    justify-content: space-between;

    div{
      padding: 0px;
    }
  }
  .modal-body-milvus {
    padding: 18px;
  }
  .modal-footer-milvus {
    border-top: 1px solid #CFD1D3;
  }
  .modal-title {
    font-size: 18px;
    color: #515151;
    font-weight: 700;
  }
}

.modal-custom-milvus {
  .modal-dialog {
    margin-top: 0px !important;
  }
  transition: all 300ms ease-in-out;
  .modal-pequeno {
    width: 500px;
    height: 420px !important;
    max-width: unset;

    .modal-content {
      max-height: 100% !important;

      .modal-body {
        min-height: 150px !important;
        max-height: 550px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  
  .modal-medio {
    width: 800px;
    height: 420px !important;
    max-width: unset;

    .modal-content {
      max-height: 100% !important;

      .modal-body {
        min-height: 265px;
        max-height: 560px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  .modal-grande {
    width: 1150px;
    height: 700px !important;
    max-width: unset;

    .modal-content {
      max-height: 100% !important;

      .modal-body:not(.modal-body-none-min-width) {
        min-height: 560px;
        max-height: 560px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  

  .modal-full-screen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: unset;

    .modal-dialog {        
      margin-top: 0px !important;
    }

    .modal-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0;

      .modal-body {
        position: absolute;
        top: 50px;
        bottom: 0;
        width: 100%;
        overflow: auto;
      }
    }
  }

  .modal-grande-encerrar-chat {
    width: 1150px;
    max-width: unset;

    .modal-content {
      max-height: calc(100vh + 5.5rem) !important;
      .modal-body {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
}

.modal-custom-arquivos {
  .modal-dialog {
    margin-top: 0px !important;
    width: 1000px; 
    max-width: 1000px; 
    height: calc(100vh - 110px); 
  }
  transition: all 300ms ease-in-out;
  .modal-pequeno {
    width: 500px;
    height: 420px !important;
    max-width: unset;

    
  }
  
  .modal-medio {
    width: 90%;
    height: calc(100vh - 110px) !important;
    max-width: unset;

    .modal-content {
      max-height: calc(100vh - 110px) !important;

      .modal-body {
        min-height: calc(100vh - 110px);
        max-height: calc(100vh - 110px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  .modal-grande {
    width: 90%;
    height: 90vh !important;
    max-width: unset;

    .modal-content {
      max-height: 100% !important;

      .modal-body:not(.modal-body-none-min-width) {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  

  .modal-full-screen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: unset;

    .modal-dialog {        
      margin-top: 0px !important;
    }

    .modal-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0;

      .modal-body {
        position: absolute;
        top: 50px;
        bottom: 0;
        width: 100%;
        overflow: auto;
      }
    }
  }

  .modal-grande-encerrar-chat {
    width: 1150px;
    max-width: unset;

    .modal-content {
      max-height: calc(100vh + 5.5rem) !important;
      .modal-body {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
}

@media screen and (max-width: 1366px) {

  .modal-custom-milvus {
    transition: all 300ms ease-in-out;

    .modal-full-screen {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      max-width: unset;      
  
      .modal-content {
        //position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;

        .modal-body {
          position: absolute;
          top: 50px;
          bottom: 0;
          width: 100%;
          overflow: auto;
        }
      }
    }
  }
}
