//
// Breadcrumb
//

.breadcrumb-item {
  font-size: $font-size-sm;
}

.breadcrumb-dark {
  background-color: $breadcrumb-dark-bg;

  .breadcrumb-item {
    font-weight: 700;

    + .breadcrumb-item {
      &::before {
        color: $breadcrumb-dark-divider-color;
      }
    }

    &.active {
      color: $breadcrumb-dark-active-color;
    }
  }
}

// Links

.breadcrumb-links {
  padding: 0;
  margin: 0;
  background: transparent;
}

.page-title {
  font-size: 24px;
  font-family: "PoppinsBold" !important;
}

.page-subtitle {
  font-size: 18px;
}
