//
// Image
//

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-ia {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5rem;
  height: 3rem;
  padding-top: 0.5rem;
}
