//
// Overflow
//

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.table-responsive:hover::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover{
  background: #666666;
}
::-webkit-scrollbar-track{
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}
perfect-scrollbar>.ps.ps--active-y>.ps__rail-y.ps--clicking, perfect-scrollbar>.ps.ps--active-y>.ps__rail-y:hover {
  width: 5px;
  background: transparent;
}
perfect-scrollbar>.ps.ps--scrolling-x>.ps__rail-x, perfect-scrollbar>.ps.ps--scrolling-y>.ps__rail-y {
  background-color: #eee0 !important;
}
