//
// Icon
//

.icon {
  width: $icon-size;
  height: $icon-size;

  i,
  svg {
    font-size: $icon-size - 0.75;
  }

  + .icon-text {
    padding-left: 1rem;
    width: calc(100% - #{$icon-size} - 1);
  }
}

// Extra large icons

.icon-xl {
  width: $icon-size-xl;
  height: $icon-size-xl;

  i,
  svg {
    font-size: $icon-size-xl - 0.75;
  }

  + .icon-text {
    width: calc(100% - #{$icon-size-xl} - 1);
  }
}

// Large icons

.icon-lg {
  width: $icon-size-lg;
  height: $icon-size-lg;

  i,
  svg {
    font-size: $icon-size-lg - 0.75;
  }

  + .icon-text {
    width: calc(100% - #{$icon-size-lg} - 1);
  }
}

// Small icon

.icon-sm {
  width: $icon-size-sm;
  height: $icon-size-sm;

  i,
  svg {
    font-size: $icon-size-sm - 0.75;
  }

  + .icon-text {
    width: calc(100% - #{$icon-size-sm} - 1);
  }
}

// Extra Small icon

.icon-xs {
  width: $icon-size-xs;
  height: $icon-size-xs;

  i,
  svg {
    font-size: $icon-size-xs - 0.75;
  }

  + .icon-text {
    width: calc(100% - #{$icon-size-xs} - 1);
  }
}

.icon-projects-white {
  background: url('../../../img/icons/svg/icone_projetos_branco.svg');
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  display: block;
  margin-top: 1px !important;
  margin-bottom: 5px;
}

.icon-projects-gray {
  background: url('../../../img/icons/svg/icone_projetos_cinza.svg');
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  display: block;
}

.icon-projects-blue {
  background: url('../../../img/icons/svg/icone_projetos_azul.svg');
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: block;
}

.hexagon-bottom {
  height: 0;
  width: 14px;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  position: relative;
}

.hexagon-bottom:before {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border-top: 10px solid #515151;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  bottom: -2px;
  left: 0px;
}

.hexagon-top {
  height: 0;
  width: 32px;
  border-top: 30px solid #515151;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  position: relative;
}

.hexagon-top:before {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border-bottom: 10px solid #515151;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  bottom: 26px;
  left: 0px;
  border-radius: 10px;
}

.hexagon-icon {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  margin-top: 5px;
  margin-left: 18px;
  font-size: 20px;
  cursor: pointer;
}
