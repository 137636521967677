.custom-checkbox {
  .custom-control-input:enabled ~ .custom-control-label {
    cursor: pointer;
    font-size: $font-size-sm;
    height: $custom-control-indicator-size;
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: #3f73e9;
        }
        &::after {
          background-image: $custom-checkbox-indicator-icon-checked;
        }
      }
    }

    &:disabled {
      cursor: not-allowed !important;
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-disabled-bg;
        }
      }

      &:checked {
        &::before {
          border-color: $custom-control-indicator-checked-disabled-bg;
        }
      }
    }
  }

  .custom-control-input:disabled ~ .custom-control-label {
    cursor: not-allowed !important;
  }

  .custom-control-input:disabled {
    cursor: not-allowed !important;
  }
}

@each $color, $value in $theme-colors {
  .custom-checkbox-#{$color} {
    @include custom-checkbox-variant($value);
  }
}