:host { display: contents; }

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url(./assets/fonts/poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url(./assets/fonts/poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "arialblack";
  src: local("arialblack"),
    url(./assets/fonts/arial-black/arial-black.ttf) format("truetype");
}

@import "~@mdi/font/css/materialdesignicons.css";

// -------------------- SYNCFUSION MODULES -------------------- //
@import "../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-spreadsheet/styles/material.css";
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';

// -------------------- SYNCFUSION MODULES DARK MODE-------------------- //
// @import "../node_modules/@syncfusion/ej2-angular-schedule/styles/material-dark.css";
// @import "../node_modules/@syncfusion/ej2-base/styles/material-dark.css";
// @import "../node_modules/@syncfusion/ej2-buttons/styles/material-dark.css";
// @import "../node_modules/@syncfusion/ej2-calendars/styles/material-dark.css";
// @import "../node_modules/@syncfusion/ej2-dropdowns/styles/material-dark.css";
// @import '../node_modules/@syncfusion/ej2-gantt/styles/material-dark.css';
// @import "../node_modules/@syncfusion/ej2-grids/styles/material-dark.css";
// @import "../node_modules/@syncfusion/ej2-inputs/styles/material-dark.css";
// @import '../node_modules/@syncfusion/ej2-layouts/styles/material-dark.css';
// @import "../node_modules/@syncfusion/ej2-lists/styles/material-dark.css";
// @import "../node_modules/@syncfusion/ej2-navigations/styles/material-dark.css";
// @import "../node_modules/@syncfusion/ej2-popups/styles/material-dark.css";
// @import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material-dark.css";
// @import "../node_modules/@syncfusion/ej2-spreadsheet/styles/material-dark.css";
// @import '../node_modules/@syncfusion/ej2-treegrid/styles/material-dark.css';

@import 'bootstrap/scss/bootstrap';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';

body {
  font-family: "PoppinsRegular" !important;
  background-color: white !important;
  padding-bottom: 20px !important;
  margin: 0;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

@import "@ctrl/ngx-emoji-mart/picker";

@import "../node_modules/@syncfusion/ej2-angular-diagrams/styles/material.css";

.main-content-with-alerts {
  .fixed-header {
    top: 90px !important;
  }
}

label,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ul {
  cursor: context-menu;
}

.ngx-spinner-overlay {
  cursor: progress;

  &div,
  img {
    cursor: progress;
  }
}

button {

  &>span,
  i {
    cursor: pointer !important;
  }
}

button:disabled {
  cursor: no-drop !important;

  &>span,
  i {
    cursor: no-drop !important;
  }
}

.cdk-drag {
  cursor: grab;

  &>div,
  .card,
  .row,
  .col-12,
  .col-6 {
    cursor: grab;
  }
}

.cdk-drop-list-dragging {
  cursor: grabbing;

  .cdk-drag {
    cursor: grabbing;

    &>div,
    .card,
    .row,
    .col-12,
    .col-6 {
      cursor: grabbing;
    }
  }
}

.card-dash-afazer,
.card-dash-expirados,
.card-dash-internos,
.card-dash-externos,
.card-dash-pausados,
.card-dash-retidos,
.card-dash-retornados,
.card-dash-conferencia,
.card-dash-meus,
.card-dash-abertos,
.card-dash-sla-prestes,
.card-dash-sla-sem-cat,
.card-dash-inventario-disp,
.card-dash-disp-critico,
.card-dash-link-todos {
  cursor: pointer !important;

  &>.card-body {
    cursor: pointer !important;

    &>div,
    .row,
    .col-12,
    .col-6 {
      cursor: pointer !important;
    }
  }
}

.avatar {
  cursor: pointer;
}

.btn-tutorial-video {
  cursor: help !important;
}

input::placeholder,
.ng-placeholder {
  font-style: italic !important;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

.modal-backdrop {
  --bs-backdrop-opacity: 1 !important;
}

.swal2-container,
.toast-container {
  z-index: 9999999 !important;
}

ngx-spinner {
  z-index: 99999999 !important;
}

.ngx-spinner-overlay {
  z-index: 99999999 !important;
}

.ngx-spinner-overlaydiv,
.ngx-spinner-overlay img {
  z-index: 99999999 !important;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='poppins']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='poppins']::before {
  content: 'Poppins';
  font-family: 'PoppinsRegular';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before {
  content: 'Arial';
  font-family: 'Arial';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times-new-roman']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times-new-roman']::before {
  content: 'Times New Roman';
  font-family: 'Times New Roman';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='helvetica']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='helvetica']::before {
  content: 'Helvetica';
  font-family: 'Helvetica';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times']::before {
  content: 'Times';
  font-family: 'Times';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='courier-new']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='courier-new']::before {
  content: 'Courier New';
  font-family: 'Courier New';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='verdana']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before {
  content: 'Verdana';
  font-family: 'Verdana';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='courier']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='courier']::before {
  content: 'Courier';
  font-family: 'Courier';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial-narrow']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial-narrow']::before {
  content: 'Arial Narrow';
  font-family: 'Arial Narrow';
}

/****************************************************
Set the font-family content used for the HTML content.
*****************************************************/

.ql-font-poppins {
  font-family: 'PoppinsRegular' !important;
}

.ql-font-arial {
  font-family: 'Arial' !important;
}

.ql-font-times-new-roman {
  font-family: 'Times New Roman' !important;
}

.ql-font-helverica {
  font-family: 'Helvetica' !important;
}

.ql-font-times {
  font-family: 'Times' !important;
}

.ql-font-courier-new {
  font-family: 'Courier New' !important;
}

.ql-font-verdana {
  font-family: 'Verdana' !important;
}

.ql-font-courier {
  font-family: 'Courier' !important;
}

.ql-font-arial-narrow {
  font-family: 'Arial Narrow' !important;
}

.global-attention-icon {
  background-image: url('assets/img/icons/svg/global-attention-icon.svg');
  background-repeat: no-repeat;
}

.tooltip_attachment .tooltip-inner {
  width: 400px !important;
  max-width: 400px !important;
}

.tooltip-expedientes .tooltip-inner {
  width: 300px !important;
  max-width: 300px !important;
  display: grid;
}

.modal-custom-1000x600 .modal-dialog {
  width: 1000px; /* Define a largura */
  max-width: 1000px; /* Garante que o modal não exceda o tamanho */
  height: 600px; /* Define a altura */
  max-height: 600px; /* Garante que o modal não exceda o tamanho */
}

.modal-custom-1000x600 .modal-content {
  height: 100%; /* Faz com que o conteúdo ocupe toda a altura do modal */
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 60%;
}