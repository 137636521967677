// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  @include font-size($badge-font-size);
  font-weight: $badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);
  @include transition($badge-transition);

  @at-root a#{&} {
    @include hover-focus {
      text-decoration: none;
    }
  }

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: 14px !important;
  padding-left: 14px !important;
  @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

//
// Headers Badge
//

.badge-user-status {
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 8px;
  border: 2px solid white;
  margin: 17px 0px 0px 35px;
}

.badge-user-status-online {
  background-color: #71E15A;
}

.badge-user-status-busy {
  background-color: #FF5555;
}

.badge-user-status-offline {
  background-color: #aaaaaa;
}

.badge-header-notification {
  background-color: #ed5356;
  width: 10px;
  height: 10px;
  position: absolute;
  border: 2px solid white;
  border-radius: 6px;
  top: 2px;
  margin-left: 14px;
}
