:root {
    --background-default: #fff;
    --background-itens: #fff;
    --color-inside: #5f5f5f;
    --color-light-inside: #e9ecef;
    --color-dark-inside: #e0e2e3;
    --color-headers: #414141;
    --color-text-dark: #777777;
    --color-text-dark-black: #000;
    --color-dividers: #dbdbdb;
    --color-hover-dropdown-itens: #ebf5ff;
    --color-comum-areas: #f7f7f7;
    --color-google-schedule: #1e88e5;
    --color-log-error: #5f5f5f;

    --background-alerts: #fff;
    --background-a-fazer: #FF5555;
    --background-atendendo: #81DA58;
    --background-pausado: #FCCC48;
    --background-retornados: #58C6DA;
    --background-expirados: #E7A343;
    --background-help: #188AFF;
    --background-info: #11cdef;
    --background-info2: #5E72E4;
    --background-success-dark: #03A79D;
    --background-pink: #e41fa9;
    --background-purple: #BE8DD2;
    --background-mv-purple: #800080;
    --background-darkblue: #3f73e9;
    --background-darkgray: #868686;
    --background-warning: #fb6340;
    --background-success: #2dce89;
    --background-danger: #f5365c;
    --background-swal-btn-confirm: #2dce89;
    --background-swal-btn-deny: #FF5555;
    --background-swal-btn-cancel: #757575;
    --background-light: #dee2e6;
    --backgrond-btn-default: #172b4d;
    --border-btn-default: #172b4d;
    --background-primary: #5e72e4;
    --background-dakrpurple: #9B549C;
    --border-btn-outline-secondary: #4385b1;
    --background-btn-darkgreen: #629B9C;
    --background-td-stripped: #D8D8D8;
    --background-tr-stripped-red: rgb(248, 187, 187);
    --background-datepicker-range: #e9edf0;
    --background-bg-lightness: #fff;
    --background-line-gantt-selected: #e2e5f4;
    --background-integration: #ff9900;

    --background-chat-agf: #B56065;
    --background-chat-agat: #FB6340;
    --background-chat-aten: #268F62;
    --background-chat-hoje: #007A7D;
    --background-vul-medio: #FFB346;
    --border-vul-critico: #404040;
    --background-sla-prestes: #B6B6B6;
    --background-link-http: #757575;
    --background-chat-msg-origem: #d9f2db;
    --background-chat-msg-origem-error: #f8d7da;
    --background-chat-msg-destino: #e2e2e2;
    --background-hover-condicoes-filtros: #eeeeee;
    --background-hover-drop-opt-filtros: #b6ccff;
    --background-user-sem-foto: #ccc9c9;
    --background-import-custom-report-button: #fe9800;
    --background-import-custom-report-button-modal: #2dce89;
    --background-import-custom-report-button-modal-disable: #7E7E7E;
    --background-dashboard-aprovacao-interno: #fca757;
    --background-dashboard-aprovacao-externo: #58c6da;

    --color-default: #000000;
    --color-see-more-transcription: #2278FA;
    --color-a-fazer: #fff;
    --color-atendendo: #fff;
    --color-pausado: #fff;
    --color-retornados: #fff;
    --color-expirados: #fff;
    --color-alert: #fff;
    --color-help: #fff;
    --color-info: #fff;
    --color-info2: #fff;
    --color-success-dark: #fff;
    --color-pink: #fff;
    --color-purple: #fff;
    --color-mv-purple: #fff;
    --color-darkblue: #fff;
    --color-darkgray: #fff;
    --color-outline-darkblue: #fff;
    --color-warning: #fff;
    --color-success: #fff;
    --color-success-import-json: #2dce89;
    --color-danger: #fff;
    --color-notificacao: #fff;
    --color-shadow: #c7c7c7;
    --color-lbl-success: #fff;
    --color-lbl-warning: #fff;
    --color-lbl-danger: #fff;
    --color-lbl-info: #fff;
    --color-lbl-task-entrega: #fff;
    --color-lbl-task-retirada: #fff;
    --color-linha-ticket-hover: #e8f0f9;
    --color-device-row-hover: #e8f0f9;
    --color-table-borders: #dfdfdf;
    --color-disabled: #cfcfcf;
    --color-btn-default: #fff;
    --color-btn-primary: #fff;
    --color-darkpurple: #fff;
    --color-darkgreen: #fff;
    --color-integration: #fff;

    --color-dash-agatendimento: #fff;
    --color-dash-afazer: #fff;
    --color-dash-expirados: #fff;
    --color-dash-internos: #fff;
    --color-dash-externos: #fff;
    --color-dash-pausados: #fff;
    --color-dash-retidos: #fff;
    --color-dash-retornados: #fff;
    --color-dash-conferencia: #fff;
    --color-dash-meus: #fff;
    --color-dash-abertos: #fff;
    --color-dash-sla-estourados: #fff;
    --color-dash-sla-prestes: #fff;
    --color-dash-sla-sem-cat: #fff;
    --color-dash-sla-pausados: #fff;
    --color-dash-inventario-disp: #fff;
    --color-dash-inventario-alertas: #fff;
    --color-dash-disp-critico: #fff;
    --color-dash-link-todos: #fff;
    --color-chat-agf: #fff;
    --color-chat-agat: #fff;
    --color-chat-aten: #fff;
    --color-chat-hoje: #fff;
    --color-vul-medio: #fff;
    --color-sla-prestes: #fff;
    --color-datepicker-weekdays: #9aaec1;
    --color-datepicker-week: #54708b;
    --color-input-search-filtros: #dcdcdc8f;
    --color-condicoes-filtros: #dedede;
    --color-info-chat-transferencia: #004eff;
    --color-dashboard-aprovacao-interno: #fff;
    --color-dashboard-aprovacao-externo: #fff;

    --box-shadow-tables: #e9ecef;
    --box-shadow-card: 0 0 2rem 0 rgb(134 134 134 / 15%);
    --box-shadow-modal-content: 0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 17%);
    --card-hover-shadow: 0 0 4rem 7px rgb(134 134 134 / 34%);

    --color-exit-button: #2F7BEE;
    --border-exit-button: #fff;

    --opacity-disabled: 1;
    --opacity-dash-icons: 1;
    --opacity-border-dash-icons: 0;

    --background-icon-view-gantt: url('../../../img/icons/svg/icone_projetos_branco.svg');
    --background-editor-mention: #0341341a;
    --color-editor-mention: #489dff;
    --brightness-template-editor: 0.1;
    --background-msg-erro-chat: #f8d7da;
    --background-explicacao-erro-chat: #f7e9ea;
    // --background-hover-items-cat-tree: #dedede;
}

// Input sidebar
.input-transparente {
    text-shadow: 0 0 0 var(--color-headers);
    color: transparent !important;
}

html,
body,
.bg-white,
.main-content,
.modal-content,
.navbar,
.fixed-header,
.log-chamado,
.row-fixed-modal {
    background-color: var(--background-default) !important;
    color: var(--color-inside);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
label,
.log-body,
.header-title,
.card-title,
.modal-title {
    color: var(--color-headers) !important;
}

.form-control,
.form-control[readonly],
.input-group,
.input-group-text {
    background-color: var(--background-itens);
    border-top-color: var(--color-dividers);
    border-left-color: var(--color-dividers);
    border-bottom-color: var(--color-dividers);
    border-right-color: var(--color-dividers);
    color: var(--color-inside);

    &:focus {
        background-color: var(--background-itens);
        color: var(--color-inside);
    }
}

.required {
    border-top-color: red !important;
}

.ng-select.custom>.ng-select-container,
.ng-select>.ng-select-container {
    background-color: var(--background-itens);
    color: var(--color-inside);
    border-color: var(--color-dividers);
}

.ng-select.custom.ng-select-disabled>.ng-select-container,
.ng-select.ng-select-disabled>.ng-select-container {
    background-color: var(--color-disabled) !important;
    color: var(--color-inside) !important;
    opacity: var(--opacity-disabled) !important;
}

.ng-dropdown-panel {
    background-color: var(--background-itens) !important;
    border-color: var(--color-dividers);
}

.ng-dropdown-panel.ng-select-bottom {
    border-top-color: var(--color-dividers);
}

.ng-dropdown-panel>.ng-dropdown-footer {
    border-top: 1px solid var(--color-dividers);
}

.ng-option {
    background-color: var(--background-itens) !important;
    color: var(--color-inside) !important;

    &:hover {
        background-color: var(--color-hover-dropdown-itens) !important;
    }
}

.ng-option.ng-option-selected {
    background-color: var(--color-hover-dropdown-itens) !important;
}

.text-dark {
    color: var(--color-text-dark) !important;
}

.text-google-schedule {
    color: var(--color-google-schedule) !important;
}

a:hover,
a.text-dark:hover,
.text-dark:hover {
    color: var(--color-inside) !important;
}

.btn-success,
.btn-mv-primary {
    color: var(--color-success) !important;
    border-color: #2dce89 !important;
    background-color: var(--background-success) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-danger {
    color: var(--color-danger) !important;
    border-color: #f5365c !important;
    background-color: var(--background-danger) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-exit {
    color: var(--color-exit-button) !important;
    border-color: var(--border-exit-button) !important;
    background-color: var(--background-default) !important;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-tutorial {
    background-color: var(--background-default) !important;
    color: var(--color-inside) !important;
    border-color: #fff !important;
}

.btn-primary {
    background-color: var(--background-primary) !important;
    border-color: #5e72e4 !important;
    color: var(--color-btn-primary) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-warning {
    color: var(--color-warning) !important;
    border-color: #fb6340 !important;
    background-color: var(--background-warning) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-integration {
    color: var(--color-integration) !important;
    border-color: #ff9900 !important;
    background-color: var(--background-integration) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-alert {
    color: var(--color-alert) !important;
    border-color: #FCCC48 !important;
    background-color: var(--background-pausado) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-info {
    color: var(--color-info) !important;
    border-color: #11cdef !important;
    background-color: var(--background-info) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-help {
    color: var(--color-help) !important;
    border-color: #188AFF !important;
    background-color: var(--background-help) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-info-2 {
    color: var(--color-info2) !important;
    border-color: #5E72E4 !important;
    background-color: var(--background-info2) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-success-dark {
    color: var(--color-success-dark) !important;
    border-color: #03A79D !important;
    background-color: var(--background-success-dark) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-pink {
    color: var(--color-pink) !important;
    border-color: #e41fa9 !important;
    background-color: var(--background-pink) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-purple {
    color: var(--color-purple) !important;
    border-color: #BE8DD2 !important;
    background-color: var(--background-purple) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-mv-purple {
    color: var(--color-mv-purple) !important;
    border-color: #800080 !important;
    background-color: var(--background-mv-purple) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-darkblue {
    color: var(--color-darkblue) !important;
    border-color: #3f73e9 !important;
    background-color: var(--background-darkblue) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-darkgray {
    color: --color-darkgray !important;
    border-color: #868686 !important;
    background-color: var(--background-darkgray) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-import-custom-report-modal {
    color: var(--color-light-inside) !important;

    border-color: var(--background-import-custom-report-button-modal) !important;
    background-color: var(--background-import-custom-report-button-modal) !important;
}

.btn-import-custom-report-modal-disable {
    color: var(--color-light-inside) !important;

    border-color: var(--background-import-custom-report-button-modal-disable) !important;
    background-color: var(--background-import-custom-report-button-modal-disable) !important;
}

.btn-outline-default,
.btn-outline-default-milvus {
    color: var(--color-inside) !important;
}

.btn-outline-darkblue {
    color: #3f73e9 !important;
    border-color: #3f73e9 !important;
    background-color: white !important;
}

.btn-outline-secondary {
    border: 2px solid var(--border-btn-outline-secondary) !important;
}

.btn-outline-dark-secondary {
    background-color: var(--background-default) !important;
    color: #172B4D !important;
    border-color: #172B4D !important;
}

.btn-outline-dark-secondary:hover {
    background-color: #172B4D !important;
}

.btn-outline-warning-milvus {
    background-color: var(--background-default) !important;
    color: #FFB346 !important;
    border-color: #FFB346 !important;
}

.btn-outline-warning-milvus:hover {
    background-color: #FFB346 !important;
}

.btn-outline-secondary-milvus {
    background-color: var(--background-default) !important;
    color: #5E72E4 !important;
    border-color: #5E72E4 !important;
}

.btn-outline-secondary-milvus:hover {
    background-color: #5E72E4 !important;
}

.btn-outline-default-milvus {
    background-color: var(--background-default) !important;
    border-color: #C4C4C4 !important;
}

.btn-outline-success:disabled,
.btn-outline-danger:disabled {
    border-color: #7e7e7e !important;
    color: #7e7e7e !important;
}

.btn-outline-default-milvus:hover {
    background-color: #C4C4C4 !important;
    color: #2a2a2a !important;
    border-color: #2a2a2a !important;
}

.btn-light {
    background-color: var(--background-light) !important;
    color: var(--color-inside) !important;
}

.btn-import-custom-report {
    background-color: var(--background-import-custom-report-button) !important;
    color: var(--color-success) !important;
    border-color: var(--color-success) !important;
}

.span-import-json {
    color: var(--color-success-import-json) !important;
}

.btn-default {
    color: var(--color-btn-default) !important;
    // background-color: var(--background-btn-default) !important;
    border-color: var(--border-btn-default) !important;

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.btn-darkpurple {
    background-color: var(--background-dakrpurple) !important;
    color: var(--color-darkpurple) !important;
    border: 2px solid #9B549C !important;

    &:hover {
        color: var(--color-darkpurple) !important;
    }

    &:disabled {
        color: white !important;
        border-color: #7e7e7e !important;
        background-color: #7e7e7e !important;
    }
}

.card,
.card-header,
.bs-datepicker {
    background-color: var(--background-itens);
    color: var(--color-inside);
    box-shadow: var(--box-shadow-card);
}

.card:not(.card-editar-ticket):hover {
    box-shadow: var(--card-hover-shadow) !important;
}

.card-frame {
    background-color: var(--background-default) !important;
    color: var(--color-inside);
    box-shadow: var(--box-shadow-card);
}

.card-a-fazer {
    background-color: var(--background-a-fazer);
    color: var(--color-a-fazer);
    border: 2px solid #FF5555;
}

.card-atendendo {
    background-color: var(--background-atendendo);
    color: var(--color-atendendo);
    border: 2px solid #81DA58;
}

.card-pausado {
    background-color: var(--background-pausado);
    color: var(--color-pausado);
    border: 2px solid #FCCC48;
}

.card-retornado {
    background-color: var(--background-retornados);
    color: var(--color-retornados);
    border: 2px solid #58C6DA;
}

.card-expirado {
    background-color: var(--background-expirados);
    color: var(--color-expirados);
    border: 2px solid #E7A343;
}

.btn-white-danger {
    background-color: var(--background-default);
    color: #FF5555;
    border: 2px solid var(--color-a-fazer);
}

.btn-white-light-green {
    background-color: var(--background-default);
    color: #81DA58;
    border: 2px solid var(--color-atendendo);
}

.btn-white-orange {
    background-color: var(--background-default);
    color: #FCCC48;
    border: 2px solid var(--color-pausado);
}

.btn-white-light-blue {
    background-color: var(--background-default);
    color: #58C6DA;
    border: 2px solid var(--color-retornados);
}

.btn-white-light-brown {
    background-color: var(--background-default);
    color: #E7A343;
    border: 2px solid var(--color-expirados);
}

.btn-outline-success-milvus {
    color: #81C04D;
    border-color: #81C04D;
    background-color: var(--background-default);
}

.btn-outline-warning {
    background-color: var(--background-default);
}

.btn-darkgreen {
    background-color: var(--background-btn-darkgreen);
    color: var(--color-darkgreen);
    border: 2px solid var(--color-darkgreen);

    &:disabled {
        color: white;
        border-color: #7e7e7e;
        background-color: #7e7e7e;
    }
}

.btn-secondary,
.badge-secondary,
.custom-info-badge {
    background-color: var(--background-default);
    color: var(--color-headers);
    border: 2px solid white;

    &:disabled {
        color: white;
        border-color: #7e7e7e;
        background-color: #7e7e7e;
    }
}

.table-responsive,
.div-filters,
.shadow-filters,
.card-milvus {
    box-shadow: -1px 2px 54px 1px var(--box-shadow-tables);
}

.text-filters {
    color: var(--color-inside);
}

.table {
    color: var(--color-inside);

    .thead-light {
        th {
            color: var(--color-inside);
            background-color: var(--background-default);
        }
    }

    .row-custom {
        color: #4e4d4d !important;
        background-color: #fde5a3;

        label {
            color: #4e4d4d !important;
        }
    }

    th {
        border-top: none !important;
        border-bottom: 5px solid var(--color-table-borders) !important;
    }

    td {
        border-top-color: var(--color-table-borders) !important;
    }

    .tfoot>tr>td {
        border-color: transparent !important;
    }
}

.linha-ticket:hover {
    background-color: var(--color-linha-ticket-hover) !important;
}

.device-row:hover {
    background-color: var(--color-device-row-hover) !important;
}

.icon-notificacao {
    background-color: var(--background-default) !important;
    border: 1px solid var(--color-notificacao) !important;
}

.dropdown-menu {
    background-color: var(--background-default);
    color: var(--color-inside);

    .dropdown-item {
        color: var(--color-inside);
    }

    .dropdown-item:hover {
        background-color: var(--color-light-inside);
    }
}

.navbar .dropdown-menu-arrow:before {
    background: var(--background-default);
}

.dropdown-divider {
    border-top: 1px solid var(--color-light-inside);
}

.filtro-dropdown,
.filtro-datepicker {
    background-color: var(--background-default);
    color: var(--color-inside);
    box-shadow: 0px 4px 13px 3px var(--color-shadow);
}

.dropdown-opt-filtros,
.opt-filtros {
    color: var(--color-inside);
}

.dropdown-opt-filtros .opt-div-filtros:hover {
    background-color: var(--background-hover-drop-opt-filtros) !important;
}

.modal-header,
.modal-header-milvus {
    border-bottom: 1px solid var(--color-dividers) !important;

    .close,
    .modal-title {
        color: var(--color-headers) !important;
    }
}

.swal2-popup,
.swal2-header,
.swal2-content,
.swal2-actions,
.swal2-footer,
.swal2-title {
    background-color: var(--background-alerts) !important;
    color: var(--color-inside) !important;
}

// .swal2-confirm {
//     color: var(--color-info2) !important;
//     border: 1px solid #2dce89 !important;
//     background-color: #2dce89 !important;
// }

// .swal2-deny {
//     color: var(--color-warning) !important;
//     border: 1px solid #fb6340 !important;
//     background-color: var(--background-swal-btn-deny) !important;
// }

// .swal2-cancel {
//     color: var(--color-darkgray) !important;
//     border: 1px solid #757575 !important;
//     background-color: var(--background-swal-btn-cancel) !important;
// }

.label-nodata {
    background-color: var(--background-default) !important;
}

.label-medium-lv {
    background-color: var(--background-default) !important;
}

.label-block-success {
    background-color: var(--background-atendendo);
    border: 2px solid #81DA58;
    color: var(--color-lbl-success);
}

.label-block-warning {
    background-color: var(--background-expirados);
    border: 2px solid #E7A343;
    color: var(--color-lbl-warning);
}

.label-block-danger {
    background-color: var(--background-a-fazer);
    border: 2px solid #c72c4e;
    color: var(--color-lbl-danger);
}

.label-block-info {
    background-color: var(--background-info2);
    border: 2px solid #2aa5c4;
    color: var(--color-lbl-info);
}

.label-disp-task-entrega {
    background-color: var(--background-default);
    border: 2px solid #5E72E4;
}

.label-disp-task-retirada {
    background-color: var(--background-default);
    border: 2px solid #9B549C;
}

.divider-left {
    border-left: 1px solid var(--color-dividers);
}

.divider-right {
    border-right: 1px solid var(--color-dividers);
}

.divider-bottom {
    border-bottom: 1px solid var(--color-dividers);
}

.divider-top {
    border-top: 1px solid var(--color-dividers);
}

.input-group,
.input-group-alternative {
    background-color: var(--background-itens) !important;
    color: var(--color-inside) !important;
}

#defaultRTE_toolbar {
    background-color: var(--background-itens);

    &>.e-toolbar-items.e-toolbar-multirow {
        background-color: var(--background-itens);
    }
}

.e-toolbar-item {
    background-color: var(--background-itens);
}

.e-toolbar .e-toolbar-item .e-tbar-btn {
    background-color: var(--background-itens) !important;
}

.e-toolbar .e-tbar-btn {
    background-color: var(--background-itens);

    &>.e-btn-icon,
    .e-tbar-btn-text {
        color: var(--color-inside) !important;
    }
}

#defaultRTE_rte-view {
    background-color: var(--color-comum-areas);
}

.progress {
    background-color: var(--background-hover-condicoes-filtros) !important;
}

.checkmark {
    background-color: var(--background-itens);
}

.checkmark:after {
    background-color: var(--background-itens) !important;
}

.slider {
    background-color: var(--background-itens) !important;
}

input:checked+.slider {
    background-color: var(--background-itens) !important;
}

.ng2-tag-input__text-input {
    color: var(--color-inside) !important;
}

.form-control:disabled {
    background-color: var(--color-disabled) !important;
    opacity: var(--opacity-disabled) !important;

    .input-group,
    .input-group-alternative {
        background-color: var(--color-disabled) !important;
        opacity: var(--opacity-disabled) !important;
    }
}

.modal-content {
    box-shadow: var(--box-shadow-modal-content);
}

.card-dash-ag-atendimento {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-agatendimento);

    label {
        color: #a67a81 !important;
    }

    .icon-flag-ag-atendimento {
        background-color: rgb(220, 203, 206, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(166, 122, 129, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-afazer {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-afazer);

    label {
        color: #FF5555 !important;
    }

    .icon-flag-a-fazer,
    .icon-flag-estourados,
    .icon-flag-alertas,
    .icon-flag-alertas,
    .icon-flag-http-erro,
    .icon-flag-porta-erro {
        background-color: rgb(255, 210, 210, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(255, 85, 85, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }

    .icon-flag-alto {
        background-color: rgb(255, 210, 210, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(255, 85, 85, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 13px !important;
    }

    .icon-flag-nao-cadastrado {
        background-color: rgb(255, 210, 210, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(255, 85, 85, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 12px !important;
    }
}

.card-dash-expirados {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-expirados);

    label {
        color: rgb(231, 163, 67) !important;
    }

    .icon-flag-expirados {
        background-color: rgb(252, 233, 207, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(231, 163, 67, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-internos {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-internos);

    label {
        color: rgb(43, 185, 107) !important;
    }

    .icon-flag-interno {
        background-color: rgb(196, 255, 223, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(43, 185, 107, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-externos {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-externos);

    label {
        color: #53D58E !important;
    }

    .icon-flag-externo {
        background-color: rgb(236, 253, 244, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(83, 213, 142, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-pausados {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-pausados);

    label {
        color: #FCCC48 !important;
    }

    .icon-flag-pausados {
        background-color: rgb(255, 241, 197, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(252, 204, 72, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }

    .icon-flag-medio {
        background-color: rgb(255, 241, 197, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(252, 204, 72, var(--opacity-border-dash-icons));
        padding: 15px 0px 0px 8px !important;
    }
}

.card-dash-retidos {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-retidos);

    label {
        color: #B35757 !important;
    }

    .icon-flag-retidos {
        background-color: rgb(250, 212, 212, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(179, 87, 87, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-retornados {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-retornados);

    label {
        color: #58C6DA !important;
    }

    .icon-flag-retornados {
        background-color: rgb(207, 241, 247, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(88, 198, 218, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }

    .icon-flag-baixo {
        background-color: rgb(207, 241, 247, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(88, 198, 218, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 12px !important;
    }
}

.card-dash-conferencia {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-conferencia);

    label {
        color: #F572A8 !important;
    }

    .icon-flag-conferencia {
        background-color: rgb(253, 224, 236, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(245, 114, 168, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-meus {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-meus);

    label {
        color: #41A5F6 !important;
    }

    .icon-flag-meus {
        background-color: rgb(200, 230, 255, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(65, 165, 246, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-abertos {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-abertos);

    label {
        color: #81C04D !important;
    }

    .icon-flag-abertos {
        background-color: rgb(226, 248, 208, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(129, 192, 77, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-sla-prestes {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-sla-prestes);

    label {
        color: #FC7C5F !important;
    }

    .icon-flag-prestes-estourar {
        background-color: rgb(247, 210, 202, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(252, 124, 95, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-sla-sem-cat {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-sla-sem-cat);

    label {
        color: #9B549C !important;
    }

    .icon-flag-sem-categoria {
        background-color: rgb(245, 217, 245, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(155, 84, 156, var(--opacity-border-dash-icons));
        padding: 0px 0px 0px 6px !important;
    }
}

.card-dash-inventario-disp {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-inventario-disp);

    label {
        color: #81C04D !important;
    }

    .icon-flag-dispositivos,
    .icon-flag-aberta {
        background-color: rgb(227, 255, 204, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(129, 192, 77, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 9px !important;
    }
}

.card-dash-disp-critico {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-disp-critico);

    label {
        color: var(--color-text-dark-black) !important;
    }

    .icon-flag-critico {
        background-color: rgb(206, 206, 206, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(0, 0, 0, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 12px !important;
    }
}

.card-dash-link-todos {
    background-color: var(--background-default);
    border: 2px solid var(--color-dash-link-todos);

    label {
        color: rgb(56, 142, 60) !important;
    }

    .icon-flag-todos {
        background-color: rgb(203, 255, 206, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(56, 142, 60, var(--opacity-border-dash-icons));
        padding: 7px 0px 0px 10px !important;
    }
}

.card-chat-agat {
    background-color: var(--background-chat-agat);
    border: 2px solid #FB6340;
    color: var(--color-chat-agat);

    label {
        color: var(--color-chat-agat) !important;
    }

    .btn {
        background-color: var(--background-default);
        border: 2px solid var(--color-chat-agat);
    }
}

.card-chat-agf {
    background-color: var(--background-chat-agf);
    border: 2px solid #B56065;
    color: var(--color-chat-agf);

    label {
        color: var(--color-chat-agf) !important;
    }

    .btn {
        background-color: var(--background-default);
        border: 2px solid var(--color-chat-agf);
    }
}

.card-chat-aten {
    background-color: var(--background-chat-aten);
    border: 2px solid #268F62;
    color: var(--color-chat-aten);

    label {
        color: var(--color-chat-aten) !important;
    }

    .btn {
        background-color: var(--background-default);
        border: 2px solid var(--color-chat-aten);
    }
}

.card-chat-hoje {
    background-color: var(--background-chat-hoje);
    border: 2px solid #007A7D;
    color: var(--color-chat-hoje);

    label {
        color: var(--color-chat-hoje) !important;
    }

    .btn {
        background-color: var(--background-default);
        border: 2px solid var(--color-chat-hoje);
    }
}

.card-users-online {
    background-color: var(--background-atendendo);
    border: 2px solid var(--background-atendendo);

    label {
        color: var(--color-dash-users-on) !important;
    }

    //.icon-flag-status {
    //background-color: rgb(203, 255, 206, var(--opacity-dash-icons)) !important;
    //border: 2px solid rgb(56, 142, 60, var(--opacity-border-dash-icons));
    //padding: 7px 0px 0px 10px !important;
    //}
}

.card-users-offline {
    background-color: var(--background-a-fazer);
    border: 2px solid var(--background-a-fazer);

    label {
        color: var(--color-dash-users-off) !important;
    }
}

.card-vul-medio {
    background-color: var(--background-vul-medio);
    border: 2px solid #FFB346;
    color: var(--color-vul-medio);

    label {
        color: var(--color-vul-medio) !important;
    }

    .btn {
        background-color: var(--background-default);
        border: 2px solid var(--color-vul-medio);
    }
}

.card-vul-alto {
    background-color: var(--background-a-fazer);
    border: 2px solid #FF5555;
    color: var(--color-a-fazer);

    label {
        color: var(--color-a-fazer) !important;
    }

    .btn {
        background-color: var(--background-default);
        border: 2px solid var(--color-a-fazer);
    }
}

.card-vul-critico {
    background-color: #3b3b3b;
    border: 2px solid var(--border-vul-critico);
    color: var(--color-dash-disp-critico);

    label {
        color: var(--color-dash-disp-critico) !important;
    }

    .btn {
        background-color: var(--background-default);
        border: 2px solid var(--border-vul-critico);
        color: var(--border-vul-critico);
    }
}

.card-sla-pausados {
    background-color: var(--background-pausado);
    border: 2px solid #FCCC48;
    color: var(--color-dash-pausados);

    label {
        color: var(--color-dash-pausados) !important;
    }

    .icon-flag-pausado {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-dash-pausados);
    }
}

.card-sla-prestes {
    background-color: var(--background-sla-prestes);
    border: 2px solid #B6B6B6;
    color: var(--color-sla-prestes);

    label {
        color: var(--color-sla-prestes) !important;
    }

    .icon-flag-prestes-estourar {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-sla-prestes);
    }
}

.card-sla-estourados {
    background-color: var(--background-a-fazer);
    border: 2px solid #FF5555;
    color: var(--color-a-fazer);

    label {
        color: var(--color-a-fazer) !important;
    }

    .icon-flag-estourados {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-a-fazer);
        padding: 0px 0px 0px 4px !important;
    }
}

.card-dash-inventario {
    background-color: var(--background-a-fazer);
    border: 2px solid #FF5555;
    color: var(--color-a-fazer);

    label {
        color: var(--color-a-fazer) !important;
    }

    .icon-flag-inventario {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-a-fazer);
        padding: 7px 0px 0px 9px !important;
    }
}

.card-dash-link-http {
    background-color: var(--background-link-http);
    border: 2px solid #757575;
    color: var(--color-darkgray);

    label {
        color: var(--color-darkgray) !important;
    }

    .btn {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-darkgray);
        color: #7e7e7e;
    }
}

.card-dash-link {
    background-color: var(--background-a-fazer);
    border: 2px solid #FF5555;
    color: var(--color-a-fazer);

    label {
        color: var(--color-a-fazer) !important;
    }

    .btn {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-a-fazer);
        padding: 0px 0px 0px 1px !important;
        color: #FF5555;
    }
}

.card-dash-tarefas-aberta {
    background-color: var(--background-atendendo);
    border: 2px solid #81DA58;
    color: var(--color-atendendo);

    label {
        color: var(--color-atendendo) !important;
    }

    .icon-flag-aberta {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-atendendo);
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-tarefas-agendado {
    background-color: var(--background-dakrpurple);
    border: 2px solid #9B549C;
    color: var(--color-darkpurple);

    label {
        color: var(--color-darkpurple) !important;
    }

    .icon-flag-prestes-agendado {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-darkpurple);
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-tarefas-expirados {
    background-color: var(--background-vul-medio);
    border: 2px solid #FFB346;
    color: var(--color-vul-medio);

    label {
        color: var(--color-vul-medio) !important;
    }

    .icon-flag-expirados {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-vul-medio);
        padding: 7px 0px 0px 10px !important;
    }
}

.card-dash-canais {

    .card-header,
    .card-body {
        background-color: var(--background-default);
        color: var(--color-inside);
    }

    .icon-ag-fila {
        background-color: rgb(250, 212, 212, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(179, 87, 87, var(--opacity-border-dash-icons));
    }

    .icon-ag-at {
        background-color: rgb(251, 216, 208, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(249, 99, 66, var(--opacity-border-dash-icons)) !important;
    }

    .icon-atendendo {
        background-color: rgb(200, 240, 223, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(38, 143, 98, var(--opacity-border-dash-icons)) !important;
    }

    .icon-atendidos {
        background-color: rgb(204, 230, 244, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(41, 164, 237, var(--opacity-border-dash-icons)) !important;
    }

    .icon-total {
        background-color: rgb(206, 206, 206, var(--opacity-dash-icons)) !important;
        border: 2px solid rgb(0, 0, 0, var(--opacity-border-dash-icons)) !important;
    }
}

.card-header-graficos-canais {
    background-color: var(--background-default);
    color: var(--color-inside);
}

.e-schedule,
.e-header-cells,
.e-toolbar-items,
.e-work-cells,
.e-time-slots,
.e-resource-cells,
.e-resource-left-td {
    background-color: var(--background-itens) !important;
    color: var(--color-inside) !important;
}

.e-work-cells:hover {
    background-color: #e3165b !important;
}

.e-current-day,
.e-current-time {
    color: #e3165b !important;
}

.timeline-step {
    background-color: var(--background-itens) !important;
}

.alert-danger {
    background-color: var(--background-danger) !important;
    color: var(--color-danger) !important;

    label {
        color: var(--color-danger) !important;
    }
}

.alert-info {
    background-color: var(--background-info) !important;
    color: var(--color-info) !important;

    label {
        color: var(--color-info) !important;
    }
}

.alert-success {
    background-color: var(--background-success) !important;
    color: var(--color-success) !important;

    label {
        color: var(--color-success) !important;
    }
}

#diagramcontent,
.e-acrdn-header,
.e-accordion,
.e-remove-palette,
.e-symbolpalette .e-acrdn-content {
    background-color: var(--background-itens) !important;
}

.bs-datepicker-body {
    border: 0;
}

.bs-datepicker-body .days.weeks {
    th {
        color: var(--color-datepicker-weekdays);
    }

    td {
        color: var(--color-datepicker-week);
    }
}

.theme-dark-blue .bs-datepicker-head {
    background-color: var(--background-itens);
    color: var(--color-headers) !important;

    button {
        color: var(--color-headers) !important;
    }
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: var(--background-itens);
}

.tr-stripped {
    background-color: var(--background-td-stripped);
}

.tr-stripped-red {
    background-color: var(--background-tr-stripped-red);
}

tag {
    background-color: var(--background-itens) !important;
    color: var(--color-headers) !important;
}

.ng2-tag-input__text-input {
    background-color: var(--background-default) !important;
    color: var(--color-inside) !important;
}

.search-base-conhecimento {
    background-color: var(--background-default);
    color: var(--color-inside);
}

.mat-tree-node {
    button {
        background-color: var(--background-itens) !important;
        color: var(--color-headers) !important;
    }
}

.bs-datepicker-body table.days span.in-range:not(.select-start):before {
    background-color: var(--background-datepicker-range);
}

.e-spreadsheet .e-formula-bar-panel {
    background-color: var(--background-itens) !important;
    color: var(--color-headers) !important;
    border: none;

    span,
    button,
    textarea {
        color: var(--color-headers) !important;
    }
}

.e-sheet {

    .e-header-panel,
    .e-main-panel,
    .e-scrollbar,
    .e-column-header,
    .e-header-cell,
    .e-cell,
    .e-header-cell.e-highlight,
    .e-spreadsheet .e-sheet,
    .e-spreadsheet .e-row-header .e-header-cell,
    .e-spreadsheet .e-column-header .e-header-cell,
    .e-spreadsheet .e-selectall-container .e-header-cell {
        background-color: var(--background-itens) !important;
        color: var(--color-headers) !important;

        span,
        button,
        textarea {
            color: var(--color-headers) !important;
        }
    }
}

.e-spreadsheet .e-sheet-tab-panel,
.e-spreadsheet .e-sheet-tab-panel .e-tab-header .e-toolbar-item.e-active {
    background-color: var(--background-itens) !important;
    color: var(--color-headers) !important;

    span,
    button,
    textarea {
        color: var(--color-headers) !important;
    }
}

.flag-a-fazer {
    background-color: var(--background-a-fazer) !important;
    color: var(--color-a-fazer) !important;
    border: 2px solid #ff5555;

    .icon-flag-a-fazer {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-a-fazer);
        padding: 5px 0px 0px 7px !important;
    }
}

.flag-atendendo {
    background-color: var(--background-atendendo) !important;
    color: var(--color-atendendo) !important;
    border: 2px solid #81DA58;

    .icon-flag-atendendo {
        color: #81DA58 !important;
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-atendendo);
        padding: 5px 0px 0px 10px !important;
    }
}

.flag-pausado {
    background-color: var(--background-pausado) !important;
    color: var(--color-pausado) !important;
    border: 2px solid #FCCC48;

    .icon-flag-pausado {
        background-color: var(--background-default) !important;
        border: 2px solid var(--color-pausado);
        padding: 5px 0px 0px 8px !important;
    }
}

.flag-finalizado {
    background-color: var(--background-darkgray) !important;
    color: var(--color-darkgray) !important;
    border: 2px solid #868686;

    .icon-flag-finalizado {
        background-color: var(--background-default) !important;
        color: var(--color-headers) !important;
        border: 2px solid var(--color-darkgray);
        padding: 5px 0px 0px 7px !important;
    }
}

.ticket-code-finalizado {
    color: var(--color-headers) !important;
}

select[readonly] {
    background-color: var(--background-itens) !important;
    color: var(--color-inside) !important;
}

.not-img-log {
    color: #5f5f5f !important;
}

.msg-origem {
    background-color: var(--background-chat-msg-origem);
}

.msg-origem-error {
    background-color: var(--background-chat-msg-origem-error);
}

.msg-destino {
    background-color: var(--background-chat-msg-destino);
}

.msg-origem:before {
    border-left: 15px solid var(--background-chat-msg-origem);
    border-top: 15px solid var(--background-chat-msg-origem);
}

.msg-destino:before {
    border-right: 15px solid var(--background-chat-msg-destino);
    border-top: 15px solid var(--background-chat-msg-destino);
}

.icon-descricao-log {
    background-color: var(--background-default);
}

.dark-back {
    background-color: var(--color-light-inside) !important;
}

.darker-back {
    background-color: var(--color-dark-inside) !important;
}

.btn-bg-lightness {
    background-color: var(--background-bg-lightness) !important;
}

.btn-historico-chat {
    color: var(--color-headers) !important;
}

.ng2-tag-input.regular-theme tag {
    color: white !important;
}

#chat-msgs {
    background-color: var(--background-default);
}

.mensagem_dados {
    background-color: var(--background-default);

    &:focus {
        background-color: var(--background-default) !important;
    }
}

.card-pesquisa-global {
    background-color: var(--background-success);
    color: var(--color-success);
    border: 2px solid var(--color-success);

    .icon-lbl-pesquisa-global {
        color: var(--color-success);
    }
}

.input-search-filtros {
    >input {
        background-color: var(--color-input-search-filtros);
        border-color: var(--color-input-search-filtros);
        box-shadow: 0px 4px 10px -6px var(--color-shadow);
    }
}

.drop-filter-abas {
    >.nav-link {
        color: var(--color-headers);
    }
}

.log-chamado {
    border: 1px solid var(--color-text-dark);
}

.status-automacao {
    background-color: var(--color-text-dark);
    color: var(--color-comum-areas);
}

.filtro-condicoes:hover {
    background-color: var(--background-hover-condicoes-filtros);
}

.blockquote-theme-gpt {
    background-color: var(--background-modal-ticket-chatgpt) !important;
    color: var(--color-inside-modal-ticket-chatgpt);
}

// ------------ GANTT ------------- //
.e-gantt-dialog {
    border-radius: 20px !important;

    &>.e-dlg-header-content {
        background-color: var(--background-default) !important;
        border-radius: 10px 10px 0px 0px !important;

        &>.e-dlg-header,
        .e-icon-dlg-close {
            color: var(--color-inside) !important;
        }
    }

    &>.e-footer-content {
        border-radius: 0px 0px 10px 10px !important;
    }

    &>.e-dlg-content,
    .e-footer-content {
        background-color: var(--background-default) !important;
    }
}

.e-gantt-dialog .e-tab .e-tab-header {
    background-color: var(--background-default) !important;
}

.e-gantt-dialog .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text {
    color: var(--color-inside) !important;
    cursor: pointer !important;
}

.e-gantt-dialog .e-tab .e-tab-header .e-indicator {
    background-color: #2dce89 !important;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
    background-color: var(--background-success) !important;
    color: var(--color-success) !important;
    border: 2px solid #2dce89 !important;
    text-transform: none !important;
    padding: 9px 15px;
    border-radius: 20px;
}

.e-btn.e-flat,
.e-css.e-btn.e-flat {
    background-color: var(--background-light) !important;
    color: var(--color-inside) !important;
    border: 2px solid #dee2e6 !important;
    text-transform: none !important;
    padding: 9px 15px;
    border-radius: 20px;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
    background-color: var(--background-default) !important;
    border: none !important;
}

.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
.e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
.e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background-color: #2dce89 !important;
}

.e-tab .e-tab-header .e-toolbar-items,
.e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    border-radius: 0px 6px 0px 0px !important;
}

.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper,
.e-input-group .e-input-group-icon:last-child,
.e-input-group.e-bigger .e-input-group-icon:last-child,
.e-input-group .e-input-group-icon.e-bigger:last-child,
.e-bigger .e-input-group .e-input-group-icon:last-child,
.e-input-group.e-small .e-input-group-icon:last-child,
.e-input-group.e-small.e-bigger .e-input-group-icon:last-child,
.e-input-group.e-small .e-input-group-icon.e-bigger:last-child,
.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger:last-child,
.e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger:last-child,
.e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child,
.e-bigger .e-input-group.e-small .e-input-group-icon:last-child,
.e-input-group:not(.e-disabled) .e-input-group-icon:active,
.e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:active,
.e-grid .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-detailrowcollapse:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-detailrowexpand:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-gridheader .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-emptyrow:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow) {
    color: var(--color-inside) !important;
}

.e-grid .e-gridheader,
.e-grid .e-table,
.e-grid.e-default .e-headercell,
.e-grid.e-default .e-detailheadercell,
.e-grid .e-content {
    background-color: var(--background-default) !important;
    color: var(--color-inside) !important;
    border-color: var(--color-inside) !important;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
    color: var(--color-inside) !important;
}

// .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
//     background-color: var(--background-light) !important;
//     color: var(--color-inside) !important;
//     border: 2px solid #dee2e6 !important;
// }

// .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right, .e-toolbar .e-toolbar-items:first-child>.e-toolbar-item, .e-toolbar .e-toolbar-items:first-child>.e-toolbar-right .e-toolbar-item, .e-toolbar .e-toolbar-items.e-tbar-pos, .e-grid .e-gridcontent {
//     background-color: var(--background-default) !important;
// }

.e-grid .e-gridheader {
    border-color: var(--color-dark-inside) !important;
}

.e-gantt-dialog .e-dependent-div,
.e-grid {
    border: none !important;
}

.e-grid td.e-active {
    background-color: var(--background-line-gantt-selected) !important;
}

.e-grid .e-rowcell,
.e-gantt .e-gantt-chart .e-chart-row-border.e-lastrow,
.e-gantt .e-gantt-splitter {
    border-color: #e0e0e042 !important;
}

.e-grid.e-gridhover tr[role=row]:not(.e-disable-gridhover):not(.e-editedrow):not(.e-detailrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    background-color: var(--background-line-gantt-selected) !important;
}

.e-gantt .e-gantt-chart .e-timeline-header-container,
.e-gantt .e-gantt-chart .e-chart-root-container,
.e-gantt .e-gantt-chart .e-timeline-header-table-container>thead>tr>th {
    background-color: var(--background-default) !important;
    border-color: var(--color-dark-inside) !important;
}

.e-gantt .e-gantt-chart .e-timeline-top-header-cell,
.e-gantt .e-gantt-chart .e-timeline-single-header-cell,
.e-gantt .e-gantt-chart .e-active .e-label,
.e-gantt .e-gantt-chart .e-label,
.e-menu-icon,
.e-icons {
    color: var(--color-inside) !important;
}

::ng-deep .e-gantt .e-grid .e-icons:not(.e-stop):not(.e-check):not(.e-icon-left):not(.e-date-icon) {
    color: var(--color-inside) !important;
}

.e-splitter .e-split-bar.e-split-bar-horizontal,
.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
    background: #e0e0e042 !important;
}

.e-contextmenu-wrapper ul .e-menu-item,
.e-contextmenu-wrapper ul .e-menu-item.e-selected,
.e-contextmenu-container ul .e-menu-item,
.e-contextmenu-container ul .e-menu-item.e-selected {
    background-color: var(--background-default) !important;
    color: var(--color-inside) !important;

    &:hover {
        background-color: var(--background-line-gantt-selected) !important;
    }
}

.e-datepicker>*,
.e-calendar .e-footer-container,
.e-bigger.e-small .e-calendar .e-footer-container,
.e-dlg-content,
.e-footer-content,
.e-ddl.e-popup,
.e-popup.e-ddl .e-dropdownbase.e-nodata,
.e-popup.e-mention .e-dropdownbase.e-nodata {
    background-color: var(--background-default) !important;
    color: var(--color-inside) !important;
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title,
.e-calendar .e-content span,
.e-bigger.e-small .e-calendar .e-content span {
    color: var(--color-inside) !important;
}

.e-calendar .e-content td.e-selected.e-focused-date span.e-day {
    background-color: #2dce89 !important;
    color: white !important;
}

.e-calendar .e-content td.e-today span.e-day {
    background-color: #3f73e9 !important;
    color: white !important;
    border-color: #3f73e9 !important;
}

.e-calendar .e-content td.e-focused-date span.e-day {
    background-color: var(--background-line-gantt-selected) !important;
    color: var(--color-inside) !important;
}

.e-calendar .e-content td:hover span.e-day {
    color: var(--color-inside) !important;
}

.e-dropdownbase .e-list-item {
    background-color: var(--background-default) !important;
    color: var(--color-inside) !important;
}

.e-dropdownbase .e-list-item.e-hover,
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover {
    background-color: var(--background-line-gantt-selected) !important;
}

.e-tooltip-wrap.e-griderror,
.e-control.e-tooltip-wrap.e-popup.e-griderror {
    background-color: var(--background-danger) !important;
    border-color: #f5365c !important;
}

.e-tooltip-wrap.e-griderror .e-arrow-tip-inner.e-tip-top,
.e-tooltip-wrap.e-griderror .e-arrow-tip-outer.e-tip-top {
    color: #f5365c !important;
}

.e-tooltip-wrap.e-griderror .e-arrow-tip-inner.e-tip-top,
.e-tooltip-wrap.e-griderror .e-arrow-tip-outer.e-tip-top {
    border-bottom: 8px solid #f5365c !important;
}

.e-error {
    color: white !important;
}

.e-gantt {
    box-shadow: var(--box-shadow-card) !important;
}

.e-gantt,
.e-gantt .e-gantt-splitter {
    border-radius: 8px !important;
}

.e-splitter.e-splitter-horizontal,
.e-splitter.e-splitter-vertical {
    border: 1px solid var(--background-default) !important;
}

.e-toolbar .e-toolbar-items:first-child>.e-toolbar-item {
    background-color: var(--background-itens) !important;
}

.e-toolbar {
    background-color: var(--background-itens) !important;
}

#defaultRTE_toolbar>.e-toolbar-items.e-toolbar-multirow {
    background-color: var(--background-itens) !important;
}

.e-gantt .e-gantt-chart .e-gantt-child-progressbar-inner-div {
    background-color: #13b66f !important;
}

.icon-view-gantt {
    background: var(--background-icon-view-gantt);
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: block;
}

.btn-view-project:hover {
    .icon-view-gantt {
        background: url('../../../img/icons/svg/icone_projetos_branco.svg');
    }
}

.table> :not(caption)>*>* {
    background-color: transparent !important;
    color: inherit !important;
}

.ql-editor,
.ql-editor:focus {
    background-color: var(--background-itens);
    color: var(--color-inside);
    border: 1px solid var(--color-dark-inside) !important;
}

.ql-editor::placeholder {
    color: var(--color-inside) !important;
}

.ql-toolbar {
    background-color: var(--background-light);
    color: var(--color-dark-inside);
    border: 1px solid var(--color-dark-inside) !important;
}

.ql-stroke {
    stroke: var(--color-inside) !important;
}

.ql-fill {
    fill: var(--color-inside) !important;
}

.ql-container {
    border: 1px solid var(--color-dark-inside) !important;
}

.ql-toolbar,
.ql-snow,
.ql-picker-label {
    color: var(--color-inside) !important;
}

.ql-picker-options {
    color: var(--color-inside) !important;
    background-color: var(--background-itens) !important;
    border: 1px solid var(--color-dark-inside) !important;
}

.accordion-item,
.search-drop-cat-multi,
.e-pager {
    background-color: var(--background-default) !important;
    color: var(--color-inside) !important;
}

.accordion-button,
.accordion-button::before {
    background-color: var(--background-default) !important;
}

.category-btn-not-child:hover,
.category-name:hover {
    background-color: var(--color-condicoes-filtros);
    color: var(--color-text-dark-black);
    border-radius: 4px;
}

.ql-toolbar button.ql-template:before {
    color: var(--color-inside) !important;
}

.ql-toolbar button.ql-macros:before {
    color: var(--color-inside) !important;
}

.ql-editor.ql-blank::before {
    color: var(--color-inside) !important;
    opacity: 0.6;
}

.opt-st-sft:hover {
    background-color: var(--background-chat-msg-origem);
    cursor: pointer;
}

.opt-sft-slc {
    background-color: var(--background-chat-msg-origem);
}

.icon-cifra-circular {
    fill: var(--color-inside);
}

.icon-telegram {
    fill: var(--color-inside);
}

.transcription-audio {
    max-width: 320px;
    display: grid;
    border-radius: 10px;
    padding: 10px;
    margin-left: -10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #B1E7864D;
    color: var(--color-default);
}

.transcription-audio-log {
    display: grid;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #B1E7864D;
    color: var(--color-default);
}

.see-more-transcription {
    cursor: pointer;
    color: var(--color-see-more-transcription);
}

.mention-dropdown-item,
.ql-mention-list-item {
    background-color: var(--background-default);
    color: var(--color-default);
    cursor: pointer;
    font-family: 'PoppinsRegular';
}

.mention-dropdown-item:hover,
.ql-mention-list-item:hover {
    background-color: var(--background-hover-drop-opt-filtros);
    color: rgb(26, 26, 26);
    transition: all 200ms ease-in-out;
}

.mention {
    line-height: 29px;
}

.log-erro>*span {
    color: var(--color-log-error) !important;
}

.user-sem-foto {
    width: 35px;
    border-radius: 22px;
    background-color: var(--background-user-sem-foto);
    font-weight: bold;
    padding: 8px 14px;
    text-transform: uppercase;
}

.tecnico-tarefa-sem-img {
    width: 50px;
    border-radius: 30px;
    margin-top: 6px;
    margin-left: 10px;
    background-color: var(--background-user-sem-foto);
    font-size: 29px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    cursor: pointer;
}

// loading animations
@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.loading-cards-ticket {
    .content {
        background: var(--background-default);
        background: linear-gradient(110deg, var(--background-default) 8%, var(--color-dark-inside) 18%, var(--background-default) 33%);
        border-radius: 8px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
        box-shadow: var(--box-shadow-card);
    }
}

.ql-indent-1:not(.ql-direction-rtl) {
    margin-left: 4.5em;
}

.ql-indent-2:not(.ql-direction-rtl) {
    margin-left: 7.5em;
}

.ql-indent-3:not(.ql-direction-rtl) {
    margin-left: 10.5em;
}

.ql-indent-4:not(.ql-direction-rtl) {
    margin-left: 13.5em;
}

.ql-indent-5:not(.ql-direction-rtl) {
    margin-left: 16.5em;
}

.ql-indent-6:not(.ql-direction-rtl) {
    margin-left: 19.5em;
}

.ql-indent-7:not(.ql-direction-rtl) {
    margin-left: 22.5em;
}

.ql-indent-8:not(.ql-direction-rtl) {
    margin-left: 25.5em;
}

.ck .ck-toolbar,
.ck .ck-list,
.ck .ck-form,
.ck .ck-table-form,
.ck .ck-table-cell-properties-form,
.ck .ck-input,
.ck .ck-dropdown,
.ck .ck-labeled-field-view,
.ck .ck-image-insert-form {
    background-color: var(--background-default) !important;
}

.ck-content {
    background-color: var(--background-itens) !important;
}

.ck-button,
.ck .ck-button__label,
.ck .ck-toolbar,
.ck .ck-list,
.ck .ck-form,
.ck .ck-table-form,
.ck .ck-table-cell-properties-form,
.ck .ck-input,
.ck .ck-dropdown,
.ck .ck-labeled-field-view,
.ck .ck-image-insert-form {
    color: var(--color-inside) !important;
}

.ck-sticky-panel__content {
    border-radius: 4px 4px 0px 0px !important;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
    top: 200px;
    z-index: 999999999999999;
    margin-top: -40px;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
    background-color: var(--background-itens) !important;
    color: var(--color-default) !important;
}

.ck-content .mention {
    background-color: var(--background-editor-mention) !important;
    color: var(--color-editor-mention) !important;
}

.btn-template-novo-editor:hover {
    cursor: auto;
    filter: brightness(var(--brightness-template-editor));
}

.log-body .table td {
    border: 1px solid var(--color-inside) !important;
    border-top-color: var(--color-inside) !important;
}

.e-input-group.e-control-wrapper.e-reportlist-drop.e-ddl.e-lib.e-keyboard {
    display: none !important;
}

/* Estilo para o cabeçalho da janela de diálogo */
.e-dlg-header-content {
    background-color: var(--background-default) !important;
    /* Cor de fundo escura */
    color: var(--color-inside) !important;
    /* Texto branco */
}

.e-dlg-header-content .e-dlg-header {
    background-color: var(--background-default) !important;
    /* Cor de fundo escura */
    color: var(--color-inside) !important;
}

/* Estilo para o texto no cabeçalho */
.e-dlg-header-content .e-title-content {
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para o botão "Campo Calculado" */
.e-dlg-header-content .e-calculated-field {
    background-color: var(--background-default) !important;
    /* Cor de fundo do botão */
    color: var(--color-inside) !important;
    /* Texto do botão */
    border-color: var(--background-default) !important;
    /* Borda do botão */
}

/* Estilo para os itens da lista na lateral esquerda */
.e-field-list .e-list-text {
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para a cor de fundo dos itens selecionados */
.e-field-list .e-list-item.e-active {
    background-color: var(--background-default) !important;
    /* Cor de fundo do item selecionado */
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para o background da lista na lateral */
.e-field-list .e-list-container {
    background-color: var(--background-default) !important;
    /* Cor de fundo da lista */
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para o background do campo de pesquisa */
.e-field-list .e-input-group input {
    background-color: var(--background-default) !important;
    /* Cor de fundo do campo de pesquisa */
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para o placeholder no campo de pesquisa */
.e-field-list .e-input-group input::placeholder {
    color: var(--color-inside) !important;
    /* Placeholder com cor mais clara */
}

/* Estilo para o botão "Aplicar" */
.e-pivotfieldlist .e-footer-content button.e-apply-btn {
    background-color: var(--background-default) !important;
    /* Cor de fundo verde */
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para o botão "Cancelar" */
.e-pivotfieldlist .e-footer-content button.e-cancel-btn {
    background-color: var(--background-default) !important;
    /* Cor de fundo vermelho */
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para o conteúdo do rodapé */
.e-dlg-content,
.e-footer-content {
    background-color: var(--background-default) !important;
}

/* Estilo para o input de valores na PivotView */
.e-input.e-format-value1,
.e-input.e-format-value2 {
    background-color: var(--background-default) !important;
    /* Cor de fundo escura */
    color: var(--color-inside) !important;
    /* Texto branco */
    border-color: var(--background-default) !important;
    /* Borda do input */
}

/* Estilo para o span entre os inputs */
.e-format-value-span {
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para a div de conteúdo do tipo de gráfico */
.e-chart-type-div-content {
    background-color: var(--background-default) !important;
    /* Cor de fundo escura */
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para a opção de tipo de gráfico */
.e-chart-type-option-text,
.e-chart-type-option-container .e-input-group {
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para a caixa de seleção 'Eixos Múltiplos' */
.e-dialog-multiple-axis .e-label,
.e-dialog-show-legend .e-label {
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para o modo eixo múltiplo */
.e-multiple-axes-mode-container {
    background-color: var(--background-default) !important;
    /* Cor de fundo escura */
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo para o dropdown 'Modo eixo múltiplo' */
.e-multiple-axes-mode-container .e-input-group,
.e-multiple-axes-mode-container .e-ddl-icon {
    background-color: var(--background-default) !important;
    /* Cor de fundo do dropdown */
    color: var(--color-inside) !important;
    /* Texto branco */
    border-color: var(--background-default) !important;
    /* Borda do dropdown */
}

/* Estilo para o dropdown desabilitado */
.e-multiple-axes-mode-container .e-input.e-disabled {
    background-color: var(--background-default) !important;
    /* Cor de fundo do dropdown desabilitado */
    color: var(--color-inside) !important;
    /* Texto branco desabilitado */
    border-color: var(--background-default) !important;
    /* Borda do dropdown desabilitado */
}

/* Estilo para o ícone do dropdown */
.e-multiple-axes-mode-container .e-ddl-icon {
    color: var(--color-inside) !important;
    /* Ícone branco */
}

/* Estilo para o grupo de input de pesquisa */
.e-input-group.e-control-wrapper.e-field-list-search-input {
    background-color: var(--background-default) !important;
    /* Cor de fundo do input de pesquisa */
    border-color: var(--background-default) !important;
    /* Cor da borda */
}

.e-input-group.e-control-wrapper.e-field-list-search-input .e-input {
    color: var(--color-inside) !important;
    /* Cor do texto no campo de pesquisa */
}

.e-input-group.e-control-wrapper.e-field-list-search-input .e-clear-icon,
.e-input-group.e-control-wrapper.e-field-list-search-input .e-field-list-search-icon {
    color: var(--color-inside) !important;
    /* Cor dos ícones de limpar e pesquisar */
}

/* Estilo para os botões de pivot (EmailSolicitante, ContatoDoTicket, etc.) */
.e-pvt-btn-div .e-pivot-button {
    background-color: var(--background-default) !important;
    /* Cor de fundo do botão */
    color: var(--color-inside) !important;
    /* Cor do texto do botão */
    border-color: var(--background-default) !important;
    /* Cor da borda do botão */
}

.e-pvt-btn-div .e-pivot-button .e-pvt-btn-content,
.e-pvt-btn-div .e-pivot-button .e-icons {
    color: var(--color-inside) !important;
    /* Cor do texto e dos ícones dentro do botão */
}

/* Estilo para os indicadores de drop */
.e-drop-indicator,
.e-drop-indicator-last {
    background-color: var(--background-default) !important;
    /* Cor de fundo do indicador */
    color: var(--color-inside) !important;
    /* Cor do texto do indicador */
}

/* Estilo para o dropdown desativado */
.e-input-group.e-control-wrapper.e-disabled.e-ddl.e-lib.e-keyboard.e-valid-input {
    background-color: var(--background-default) !important;
    /* Cor de fundo do dropdown desativado */
    border-color: var(--background-default) !important;
    /* Cor da borda */
}

.e-input-group.e-control-wrapper.e-disabled.e-ddl.e-lib.e-keyboard.e-valid-input .e-input {
    color: var(--color-inside) !important;
    /* Cor do texto no dropdown desativado */
}

.e-input-group.e-control-wrapper.e-disabled.e-ddl.e-lib.e-keyboard.e-valid-input .e-input-group-icon {
    color: var(--color-inside) !important;
    /* Cor dos ícones no dropdown desativado */
}

/* Estilo específico para o checkbox 'Adiar Atualização de Layout' */
.e-checkbox-wrapper.e-wrapper label[for="PivotView_PivotFieldListDeferUpdateCheckBox"] .e-label {
    color: var(--color-inside) !important;
    /* Texto branco */
}

/* Estilo específico para o input dentro da div 'e-field-table' */
.e-field-table .e-field-list-search-input .e-input {
    background-color: var(--background-default) !important;
    /* Cor de fundo do input */
    color: var(--color-inside) !important;
    /* Cor do texto digitado */
    border-color: var(--background-default) !important;
    /* Borda do input */
}

/* Estilo específico para o placeholder dentro do input na div 'e-field-table' */
.e-field-table .e-field-list-search-input .e-input::placeholder {
    color: var(--color-inside) !important;
    /* Cor do placeholder */
}

/* Estilo específico para o input */
.e-pivot-calc-input.e-control.e-input {
    background-color: var(--background-default) !important;
    /* Cor de fundo do input */
    color: var(--color-inside) !important;
    /* Cor do texto */
    border: 1px solid var(--background-default) !important;
    /* Borda do input */
}

/* Estilo específico para o placeholder dentro do input */
.e-pivot-calc-input.e-control.e-input::placeholder {
    color: var(--color-inside) !important;
    /* Cor do placeholder */
    opacity: 0.7 !important;
    /* Reduz ligeiramente a opacidade do placeholder para diferenciar do texto normal */
}

.e-list-text {
    color: var(--color-inside) !important;
    opacity: 0.7 !important;
}

.e-pivot-formula::placeholder {
    color: var(--color-inside) !important;
    opacity: 0.7 !important;
}

.erro-envio-msg-container {
    background-color: var(--background-msg-erro-chat) !important;
    border-radius: 6px;
    padding: 3px;
}

.erro-envio-balao-chat {
    background-color: var(--background-msg-erro-chat) !important;

    &:before {
        border-left: 15px solid var(--background-msg-erro-chat) !important;
        border-top: 15px solid var(--background-msg-erro-chat) !important;
    }

    .chat-info-transferencia {
        color: var(--color-info-chat-transferencia) !important;
    }
}

.chat-info-transferencia {
    color: var(--color-info-chat-transferencia) !important;
}

/* Estilos para o modo escuro da modal */
.custom-modal-top {
    background-color: var(--background-default) !important;
    /* Cor de fundo do modal */
    color: var(--color-inside) !important;
    /* Cor do texto dentro do modal */
}

.custom-modal-header {
    background-color: var(--background-default) !important;
    /* Cor de fundo do header do modal */
    color: var(--color-inside) !important;
    /* Cor do texto do header */
    border-bottom: 1px solid var(--background-default) !important;
    /* Cor da borda inferior no header */
}

.custom-close {
    color: var(--color-inside) !important;
    /* Cor do ícone de fechar no modo escuro */
}

.custom-modal-title {
    color: var(--color-inside) !important;
    /* Cor do título dentro do modal */
}

.custom-modal-body {
    background-color: var(--background-default) !important;
    /* Cor de fundo do corpo do modal */
    color: var(--color-inside) !important;
    /* Cor do texto no corpo do modal */
}

.custom-modal-footer {
    background-color: var(--background-default) !important;
    /* Cor de fundo do rodapé */
    color: var(--color-inside) !important;
    /* Cor do texto no rodapé */
}

/* Estilo para o alerta no modal */
.alert-message {
    background-color: var(--background-default) !important;
    /* Cor de fundo do alerta */
    color: var(--color-inside) !important;
    /* Cor do texto no alerta */
}

.alert-icon {
    color: var(--color-inside) !important;
    /* Cor do ícone de alerta */
}

.alert-text {
    color: var(--color-inside) !important;
    /* Cor do texto do alerta */
}

.card-dashboard-aprovacao-interno {
    background-color: var(--background-dashboard-aprovacao-interno);
    border: 1px solid #fca757;
    color: var(--color-dashboard-aprovacao-interno);
}

.card-dashboard-aprovacao-externo {
    background-color: var(--background-dashboard-aprovacao-externo);
    border: 1px solid #58c6da;
    color: var(--color-dashboard-aprovacao-externo);
}

.card-dashboard-aprovados {
    background-color: var(--background-success);
    border: 1px solid #2dce89;
    color: var(--color-success);
}

.card-dashboard-reprovados {
    background-color: var(--background-danger);
    border: 1px solid #f5365c;
    color: var(--color-danger);
}

.e-pagercontainer {
    background-color: var(--background-default) !important;
    border-color: var(--background-default) !important;
    color: var(--color-alert) !important;
  }

  .e-numericitem,
  .e-firstpage,
  .e-prevpage,
  .e-firstpagedisabled,
  .e-prevpagedisabled,
  .e-nextpage,
  .e-lastpage,
  .e-nextpagedisabled,
  .e-lastpagedisabled {
    border-right-color: var(--background-darkgray) !important;
  }

  .e-currentitem,
  .e-currentitem:hover {
    background-color: var(--background-darkgray) !important;
  }

  .e-pager .e-nextpage, .e-prevpage {
    background-color: unset !important;
  }

  .e-pager .e-np, .e-pp {
    padding: 0px 0px 4px !important;
  }

  .e-pager .e-prevpagedisabled, .e-firstpagedisabled, .e-nextpagedisabled, .e-lastpagedisabled  {
    background-color: unset !important;
  }

  .e-checkbox-wrapper .e-label, .e-css.e-checkbox-wrapper .e-label {
    color: var(--label-text-color) !important;
  }

  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before, .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after, .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before, .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after, .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before, .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after, .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before, .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after, .e-float-input:not(.e-input-group) .e-float-line::before, .e-float-input:not(.e-input-group) .e-float-line::after, .e-float-input:not(.e-input-group) .e-float-line::before, .e-float-input:not(.e-input-group) .e-float-line::after, .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before, .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after, .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before, .e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    
  }

  .e-dialog {
    background-color:  var(--background-popup) !important;
  }

  .e-cc-searchdiv .e-input::placeholder {
    color: var(--label-text-color) !important;
  }

  .e-pager .e-numericitem {
    color: var(--grid-pager-color) !important;
    background-color: unset !important;
    border: solid 1px var(--grid-pager-border) !important;

    &:hover {
        background-color: var(--grid-pager-border) !important;
    }
  }
  
  .e-pager .e-active, .e-pager .e-currentitem {
    background-color: var(--grid-pager-active) !important;
    color: #fff !important;
    border: none !important;

    &:hover {
        background-color: var(--grid-pager-active) !important;
    }
  }