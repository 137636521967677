.custom-radio-openai {
    .custom-control-input:enabled~.custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
        height: $custom-control-indicator-size;
        line-height: $custom-control-indicator-size; // Alinha o texto verticalmente ao centro
        vertical-align: middle; // Alinha o texto verticalmente ao centro
        padding-top: 0.35rem; // Ajuste adicional para centralização
    }

    .custom-control-label::before {
        border-radius: $custom-radio-indicator-border-radius;
    }

    .custom-control-input {
        &:checked {
            ~.custom-control-label {
                &::before {
                    border-color: #3f73e9;
                }

                &::after {
                    background-color: #3f73e9;
                    border-radius: $custom-radio-indicator-border-radius;
                    background-image: $custom-radio-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            cursor: not-allowed !important;

            ~.custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }

    .custom-control-input:disabled~.custom-control-label {
        cursor: not-allowed !important;
    }

    .custom-control-input:disabled {
        cursor: not-allowed !important;
    }
}

.form-group-padding-top {
    padding-top: 1rem; // Ajuste de padding-top para dar respiro no layout
}