//
// Icon buttons
//

// General styles

.btn {
  position: relative;
  text-transform: $btn-text-transform;
  transition: $transition-base;
  letter-spacing: $btn-letter-spacing;
  font-size: $input-btn-font-size;
  will-change: transform;
  //margin-right: 0 !important;
  //margin-left: 10px !important;

  &:hover {
    @include box-shadow($btn-hover-box-shadow);
    transform: translateY($btn-hover-translate-y);
  }

  //&:not(:last-child) {
  //}
  margin-right: 0.5rem;

  // Icons

  i:not(:first-child),
  svg:not(:first-child) {
    margin-left: 0.5rem;
  }

  i:not(:last-child),
  svg:not(:last-child) {
    margin-right: 0.5rem;
  }
}

// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
  .btn {
    margin-right: 0;
    transform: translateY(0);
  }
}

// Size variations

.btn-sm {
  font-size: $input-btn-font-size-sm;
}

.btn-lg {
  font-size: $input-btn-font-size-lg;
}

// Some quick fixes (to revise)

// Fixes
[class*="btn-outline-"] {
  border-width: 1px;
}

.btn-outline-secondary {
  color: darken(theme-color("secondary"), 50%);
}

.btn-outline-default-milvus {
  color: #7C7C7C;
  border-color: #7C7C7C;

  &:hover {
    color: white;
    background-color: #4172c6;
    border-color: #4172c6;
  }

  &.dropdown-toggle:focus {
    color: white;
    background-color: #4172c6;
    border-color: #4172c6;
  }
}

.btn-inner--icon {
  i:not(.fas):not(.fab) {
    position: relative;
    top: 2px;
  }
}

.btn-link {
  font-weight: $btn-font-weight;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    transform: none;
  }
}

.btn-neutral {
  color: theme-color("primary");
}

.min-small-circled-btn {
  width: 30px;
  height: 30px;
  font-size: 12px;
  padding: 0;
  border-radius: 21px;
}

.small-circled-btn {
  width: 37px;
  height: 37px;
  border-radius: 21px;
  font-size: 17px;
}

.medium-circled-btn {
  width: 42px;
  height: 42px;
  border-radius: 21px;
  font-size: 24px;
}

.large-circled-btn {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  font-size: 28px;
}

.btn-tutorial-video {
  padding: 0.5rem 1.2rem;
  margin-left: 1em;
  margin-bottom: 0;
  background-color: red;
  color: white;
  border-radius: 7px;
}

.btn-tutorial {
  margin-left: 1em;
  background-color: #EFEFEF;
  color: #A4A4A4;
}

.btn-darkgray {
  padding: 0.5rem 0.7rem;
  margin-left: 1em;
  background: #868686;
  color: #ffffff;
}

.btn-darkgreen {
  padding: 0.5rem 0.7rem;
  background: #629B9C;
  color: #ffffff;
}

.btn-mini-rounded-milvus {
  width: 20px;
  height: 20px;
  border-radius: 40px;
  padding: 4px;
  cursor: pointer;
}

.btn-sm-rounded-milvus {
  width: 30px;
  height: 30px;
  border-radius: 40px;
  padding: 4px;
  cursor: pointer;
}

.btn-md-rounded-milvus {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  padding: 4px;
  cursor: pointer;
}

.btn-alert
{
    color: #fff;
    border-color: #FCCC48;
    background-color: #FCCC48;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-alert:hover
{
    color: #fff;
    border-color: #FCCC48;
    background-color: #FCCC48;
}

.btn-help {
  color: #fff;
  border-color: #188AFF;
  background-color: #188AFF;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-help:hover {
  color: #fff;
  border-color: #188AFF;
  background-color: #188AFF;
}

.btn-info-2 {
  color: #fff;
  border-color: #5E72E4;
  background-color: #5E72E4;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-info-2:hover {
  color: #fff;
  border-color: #5E72E4;
  background-color: #5E72E4;
}

.btn-success-dark {
  color: #fff;
  border-color: #03A79D;
  background-color: #03A79D;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-success-dark:hover {
  color: #fff;
  border-color: #03A79D;
  background-color: #03A79D;
}

.btn-pink {
  color: #fff;
  border-color: #e41fa9;
  background-color: #e41fa9;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-pink:hover {
  color: #fff;
  border-color: #e41fa9;
  background-color: #e41fa9;
}

.btn-purple {
  color: #fff;
  border-color: #BE8DD2;
  background-color: #BE8DD2;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-purple:hover {
  color: #fff;
  border-color: #BE8DD2;
  background-color: #BE8DD2;
}

.btn-darkblue {
  color: #fff;
  border-color: #3f73e9;
  background-color: #3f73e9;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-darkblue:hover {
  color: #fff;
  border-color: #3f73e9;
  background-color: #3f73e9;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}
//Pivot com JSON
.btn-darkblue-pivot {
  color: #fff;
  border-color: #00008b;
  background-color: #00008b;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-darkblue-pivot:hover {
  color: #fff;
  border-color: #00008b;
  background-color: #00008b;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

//Pivot sem JSON
.btn-darkgray-pivot-nojson {
  color: #fff;
  border-color: #868686;
  background-color: #868686;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-darkgray-pivot-nojson:hover {
  color: #fff;
  border-color: #868686;
  background-color: #868686;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

//Exportar/Download JSON
.btn-purple-pivot-export {
  color: #fff;
  border-color: #7f55f8;
  background-color: #7f55f8;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-darkgreen-vizualizar {
  color: #fff;
  border-color: #008000;
  background-color: #008000;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-darkgreen-vizualizar:hover {
  color: #fff;
  border-color: #008000;
  background-color: #008000;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-outline-darkblue {
  color: #3f73e9;
  border-color: #3f73e9;
  background-color: white;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-outline-darkblue:hover {
  color: white;
  border-color: #3f73e9;
  background-color: #3f73e9;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn-fechar-modal {
  position: absolute;
  left: 20px;
}