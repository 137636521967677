// Weight and italics

.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-900 {
  font-weight: 900 !important;
}

// Text decorations

.text-underline {
  text-decoration: underline;
}
.text-through {
  text-decoration: line-through;
}

.text-dark {
  color: #777777 !important;
}

.text-dark-pivot {
  color: #777777 !important;
  font-weight: bold;
}

.text-danger {
  color: #FF5555 !important;
}

.text-success {
  color: #2DCE89 !important;
}

.text-success-milvus {
  color: #00D7A0 !important;
}

.text-warning {
  color: #FCCC48 !important;
}

.text-warning2 {
  color: #fb6340 !important;
}

.text-info {
  color: #32CAE5 !important;
}

// Text size

.text-xs {
  font-size: $font-size-xs !important;
}
.text-sm {
  font-size: $font-size-sm !important;
}
.text-lg {
  font-size: $font-size-lg !important;
}
.text-xl {
  font-size: $font-size-xl !important;
}
.text-17 {
  font-size: 17px;
}
.text-20 {
  font-size: 20px;
}
.text-filters {
  color: #868686;
  font-size: 12px;
  margin: 16px 7px;

  &:hover {
    color: #424242
  }
}

// Line heights

.lh-100 {
  line-height: 1;
}
.lh-110 {
  line-height: 1.1;
}
.lh-120 {
  line-height: 1.2;
}
.lh-130 {
  line-height: 1.3;
}
.lh-140 {
  line-height: 1.4;
}
.lh-150 {
  line-height: 1.5;
}
.lh-160 {
  line-height: 1.6;
}
.lh-170 {
  line-height: 1.7;
}
.lh-180 {
  line-height: 1.8;
}

// Letter spacings

.ls-1 {
  letter-spacing: 0.0625rem;
}
.ls-15 {
  letter-spacing: 0.09375rem;
}
.ls-2 {
  letter-spacing: 0.125rem;
}

// Color variations

@each $color, $value in $colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

.text-filters {
  color: #868686;
  font-size: 12px;
  margin: 16px 7px;
  &:hover {
    color: #424242
  }
}

.text-filters-min {
  color: #868686;
  font-size: 12px;
  margin-top: 16px;
  margin-bottom: 20px;
  margin-left: 7px;
  margin-right: 5px;
  font-family: 'PoppinsRegular';
  &:hover {
    color: #424242
  }
}