:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{$color}: #{$value};
  }

  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  --font-family-monospace: #{inspect($font-family-monospace)};

  /* LGPD WIDGET THEME VARS */
  /* widget geral */
  --geral-background-color: rgb(255, 255, 255);
  --geral-border-color: rgb(0,0,0);
  --geral-border-width: 2px;
  --geral-color: rgb(0,0,0);
  --geral-font-family: 'Arial';
  --geral-font-size: 12px;
  /* widget abas */
  --tabs-color: rgb(0,0,0);
  --tabs-background-color: rgb(255, 255, 255);
  --tabs-color-active: rgb(0,0,0);
  --tabs-background-color-active: rgb(255, 255, 255);
  --tabs-font-family: 'Arial';
  --tabs-font-size: 12px;
  /* widget toggle */
  --toggle-ativo:  rgb(0, 0, 0);
  --toggle-inativo:  rgb(173, 173, 173);
  /* widget categorias */
  --cat-background-color: rgb(255, 255, 255);
  --cat-color: rgb(0,0,0);
  --cat-font-family: 'Arial';
  --cat-font-size: 12px;
  /* widget botoes */
  --btn-background-color: rgb(255, 255, 255);
  --btn-border-color: rgb(0,0,0);
  --btn-border-width: 2px;
  --btn-color: rgb(0,0,0);
  --btn-font-family: 'Arial';
  --btn-font-size: 12px;

  /* WIDGET INICIAL THEME VARS */
  /* geral */
  --inicial-geral-background-color: rgb(255, 255, 255);
  --inicial-geral-border-color: rgb(0,0,0);
  --inicial-geral-border-width: 2px;
  --inicial-geral-color: rgb(0,0,0);
  --inicial-geral-font-family: 'Arial';
  //--inicial-geral-font-size: 12px;
   /* botoes */
   --inicial-btn-background-color: rgb(255, 255, 255);
   --inicial-btn-border-color: rgb(0,0,0);
   --inicial-btn-border-width: 2px;
   --inicial-btn-color: rgb(0,0,0);
   --inicial-btn-font-family: 'Arial';
   --inicial-btn-font-size: 12px;

  /* MINI WIDGET THEME VARS */
  --mini-geral-background-color: rgb(255, 255, 255);
  --mini-geral-border-color: rgb(0,0,0);
  --mini-geral-border-width: 2px;
  --mini-geral-color: rgb(0,0,0);
  --mini-geral-font-family: 'Arial';
  --mini-geral-font-size: 12px;
}
